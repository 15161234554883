import * as React from 'react';

function SvgBoxArrowInDownRight(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M6.364 2.5a.5.5 0 01.5-.5H13.5A1.5 1.5 0 0115 3.5v10a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 012 13.5V6.864a.5.5 0 111 0V13.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5H6.864a.5.5 0 01-.5-.5z'
			/>
			<path
				fillRule='evenodd'
				d='M11 10.5a.5.5 0 01-.5.5h-5a.5.5 0 010-1h3.793L1.146 1.854a.5.5 0 11.708-.708L10 9.293V5.5a.5.5 0 011 0v5z'
			/>
		</svg>
	);
}

export default SvgBoxArrowInDownRight;
