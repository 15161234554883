import * as React from 'react';

function SvgUmbrella(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8 0a.5.5 0 01.5.5v.514C12.625 1.238 16 4.22 16 8c0 0 0 .5-.5.5-.149 0-.352-.145-.352-.145l-.004-.004-.025-.023a3.484 3.484 0 00-.555-.394A3.166 3.166 0 0013 7.5c-.638 0-1.178.213-1.564.434a3.484 3.484 0 00-.555.394l-.025.023-.003.003s-.204.146-.353.146-.352-.145-.352-.145l-.004-.004-.025-.023a3.484 3.484 0 00-.555-.394 3.3 3.3 0 00-1.064-.39V13.5H8h.5v.039l-.005.083a2.958 2.958 0 01-.298 1.102 2.257 2.257 0 01-.763.88C7.06 15.851 6.587 16 6 16s-1.061-.148-1.434-.396a2.255 2.255 0 01-.763-.88 2.958 2.958 0 01-.302-1.185v-.025l-.001-.009v-.003s0-.002.5-.002h-.5V13a.5.5 0 011 0v.506l.003.044a1.958 1.958 0 00.195.726c.095.191.23.367.423.495.19.127.466.229.879.229s.689-.102.879-.229c.193-.128.328-.304.424-.495a1.958 1.958 0 00.197-.77V7.544a3.3 3.3 0 00-1.064.39 3.482 3.482 0 00-.58.417l-.004.004S5.65 8.5 5.5 8.5c-.149 0-.352-.145-.352-.145l-.004-.004a3.482 3.482 0 00-.58-.417A3.166 3.166 0 003 7.5c-.638 0-1.177.213-1.564.434a3.482 3.482 0 00-.58.417l-.004.004S.65 8.5.5 8.5C0 8.5 0 8 0 8c0-3.78 3.375-6.762 7.5-6.986V.5A.5.5 0 018 0zM6.577 2.123c-2.833.5-4.99 2.458-5.474 4.854A4.124 4.124 0 013 6.5c.806 0 1.48.25 1.962.511a9.706 9.706 0 01.344-2.358c.242-.868.64-1.765 1.271-2.53zm-.615 4.93A4.16 4.16 0 018 6.5a4.16 4.16 0 012.038.553 8.688 8.688 0 00-.307-2.13C9.434 3.858 8.898 2.83 8 2.117c-.898.712-1.434 1.74-1.731 2.804a8.687 8.687 0 00-.307 2.131zm3.46-4.93c.631.765 1.03 1.662 1.272 2.53.233.833.328 1.66.344 2.358A4.14 4.14 0 0113 6.5c.77 0 1.42.23 1.897.477-.484-2.396-2.641-4.355-5.474-4.854z' />
		</svg>
	);
}

export default SvgUmbrella;
