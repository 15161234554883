import * as React from 'react';

function SvgMessenger(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 00-.427.03l-1.588.702a.64.64 0 01-.898-.566l-.044-1.423a.639.639 0 00-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459l-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 01.578-.002l1.869 1.402a1.2 1.2 0 001.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 01-.578.002L7.281 5.98a1.2 1.2 0 00-1.735.32z' />
		</svg>
	);
}

export default SvgMessenger;
