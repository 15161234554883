import * as React from 'react';

function Svg21Mp(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path opacity={0.3} d='M15 14h1.5v1.5H15z' />
			<path
				d='M5 19h14V5H5v14zm8-13.5h3v6h-1.5V7H13V5.5zm.5 7H17c.55 0 1 .45 1 1V16c0 .55-.45 1-1 1h-2v1.5h-1.5v-6zM7.5 9c0-.55.45-1 1-1h2V7h-3V5.5H11c.55 0 1 .45 1 1V8c0 .55-.45 1-1 1H9v1h3v1.5H7.5V9zM6 13.5c0-.55.45-1 1-1h4.5c.55 0 1 .45 1 1v5H11V14h-1v3H8.5v-3h-1v4.5H6v-5z'
				opacity={0.3}
			/>
			<path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z' />
			<path d='M14.5 11.5H16v-6h-3V7h1.5zM12 10H9V9h2c.55 0 1-.45 1-1V6.5c0-.55-.45-1-1-1H7.5V7h3v1h-2c-.55 0-1 .45-1 1v2.5H12V10zM7.5 14h1v3H10v-3h1v4.5h1.5v-5c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v5h1.5V14zM15 17h2c.55 0 1-.45 1-1v-2.5c0-.55-.45-1-1-1h-3.5v6H15V17zm0-3h1.5v1.5H15V14z' />
		</svg>
	);
}

export default Svg21Mp;
