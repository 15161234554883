import * as React from 'react';

function SvgGradient(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0z' fill='none' />
			<path d='M13 11h2v2h-2zm6 10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14zM9 18H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zM5 13h2v-2H5V5h14v6h-2v2h2v2h-2v-2h-2v2h-2v-2h-2v2H9v-2H7v2H5v-2zm2-4h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2zm-2 2h2v2H9z' />
		</svg>
	);
}

export default SvgGradient;
