import * as React from 'react';

function SvgSpa(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M8.55 12zm10.43-1.61z' />
			<path
				d='M8.94 12.25c0-.01 0-.01 0 0-.13-.09-.27-.17-.4-.26.13.1.27.17.4.26zm4.41-3.67a9.659 9.659 0 00-1.3-3.38c-.66 1.04-1.12 2.19-1.37 3.39.46.3.9.62 1.33.97.42-.35.87-.68 1.34-.98zm3.19 5.08l.01.02c-.09.06-.18.12-.27.17l-.07.05c-.98.71-1.84 1.61-2.53 2.66L12 19.1l-1.67-2.55c-.68-1.03-1.52-1.92-2.51-2.65l-.07-.04c-.13-.08-.26-.16-.39-.25l.01-.01a9.68 9.68 0 00-3.12-1.33c.75 3.36 3.16 6.17 6.45 7.35.42.15.84.27 1.28.36.45-.09.89-.21 1.33-.37 3.27-1.17 5.67-3.98 6.43-7.34-1.14.26-2.23.73-3.2 1.39zm-7.55-1.38'
				opacity={0.3}
			/>
			<path d='M12 15.45a12.11 12.11 0 00-3.06-3.2c-.13-.09-.27-.16-.4-.26.13.09.27.17.39.25A11.777 11.777 0 002 10c0 5.32 3.36 9.82 8.03 11.49.63.23 1.29.4 1.97.51.68-.12 1.33-.29 1.97-.51C18.64 19.82 22 15.32 22 10c-4.18 0-7.85 2.17-10 5.45zm1.32 4.15c-.44.15-.88.27-1.33.37-.44-.09-.87-.21-1.28-.36-3.29-1.18-5.7-3.99-6.45-7.35 1.1.26 2.15.71 3.12 1.33l-.02.01c.13.09.26.18.39.25l.07.04c.99.72 1.84 1.61 2.51 2.65L12 19.1l1.67-2.55a10.19 10.19 0 012.53-2.66l.07-.05c.09-.05.18-.11.27-.17l-.01-.02c.98-.65 2.07-1.13 3.21-1.4-.75 3.37-3.15 6.18-6.42 7.35zm2.17-9.97c-.18-2.79-1.31-5.51-3.43-7.63a12.188 12.188 0 00-3.55 7.63c1.28.68 2.46 1.56 3.49 2.63 1.03-1.06 2.21-1.94 3.49-2.63zm-3.44-4.44c.63 1.03 1.07 2.18 1.3 3.38-.47.3-.91.63-1.34.98-.42-.34-.87-.67-1.33-.97.25-1.2.71-2.35 1.37-3.39z' />
			<path
				d='M8.99 12.28c-.02-.01-.04-.03-.05-.04 0 0 .01 0 .01.01.01.01.02.02.04.03z'
				opacity={0.3}
			/>
		</svg>
	);
}

export default SvgSpa;
