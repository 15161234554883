import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
// eslint-disable-next-line no-unused-vars
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import reducers from './redux/reducers';
import rootSaga from './redux/sagas';
import App from './App/App';
import './i18n';

// middlewared
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [logger, sagaMiddleware, routeMiddleware];
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);

const cache = new InMemoryCache();
const link = new HttpLink({
	uri: 'https://api.tblondi.com/graphql',
});

const client = new ApolloClient({
	cache,
	link,
});
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const children = (
	<Router>
		<React.StrictMode>
			<Auth0Provider domain={domain} clientId={clientId} redirectUri={window.location.origin}>
				<ApolloProvider client={client}>
					<Provider store={store}>
						<ThemeContextProvider>
							<App />
						</ThemeContextProvider>
					</Provider>
				</ApolloProvider>
			</Auth0Provider>
		</React.StrictMode>
	</Router>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
