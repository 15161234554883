/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
import React, { lazy } from 'react';
import { mainPage } from '../menuTblondi';

const HOME = {
	MAIN: lazy(() => import('../pages/tblondi/HomeScreen')),
};

const WELCOME = {
	MAIN: lazy(() => import('../pages/tblondi/WelcomeScreen/WelcomeScreenMain/WelcomeScreen')),
	CREATOR: lazy(() =>
		import('../pages/tblondi/WelcomeScreen/WelcomeScreenRoleDetail/WelcomeScreenRoleDetail'),
	),
	BRAND: lazy(() =>
		import('../pages/tblondi/WelcomeScreen/WelcomeScreenRoleDetail/WelcomeScreenRoleDetail'),
	),
};

const DISCOVERY = {
	SOCIAL_MEDIA: lazy(() => import('../pages/tblondi/Discovery/DiscoveryPage/DiscoveryPage')),
	ITEM_SOCIAL: lazy(() =>
		import('../pages/tblondi/Discovery/DiscoveryPage/DiscoveryPageDetail/DiscoveryPageItem'),
	),
	// EVENTS: lazy(() => import('../pages/tblondi/Discovery/EventsPage/Events')),
	// ITEM_EVENT: lazy(() =>
	// 	import('../pages/tblondi/Discovery/EventsPage/EventsPageDetail/EventsPageDetail'),
	// ),
};

const PROFILE = {
	MY_PROFULE: lazy(() => import('../pages/tblondi/ProfilePage/ProfilePage')),
};

const PORTFOLIO = {
	MY_PORTFOLIO: lazy(() => import('../pages/tblondi/Portfolio/PortfolioPage/PortfolioPage')),
	PORTFOLIO_PAGE_ITEM: lazy(() =>
		import('../pages/tblondi/Portfolio/PortfolioDetailPage/PortfolioDetailItem'),
	),
	// PORTFOLIO_PAGE_EVENT: lazy(() =>
	// 	import('../pages/tblondi/Portfolio/PortfolioDetailPageEvent/PortfolioDetailPageEvent'),
	// ),
	PORTFOLIO_PAGE_ADD_TAG: lazy(() =>
		import('../pages/tblondi/Portfolio/PortfolioAddTitleCard/PortfolioAddTitleCard'),
	),
};

const CAMPAIGNS = {
	CAMPAIGNS_MAIN: lazy(() => import('../pages/tblondi/Campaigns/CampaignsMain')),
	CAMPAIGNS_LIST: lazy(() =>
		import('../pages/tblondi/Campaigns/CampaignAllList/CampaignAllList'),
	),
	CAMPAIGNS_ITEM: lazy(() =>
		import('../pages/tblondi/Campaigns/CampaignsDetail/CampaignsDetail'),
	),
};

const ASSETS_STORAGE = {
	ASSETS_STORAGE_MAIN: lazy(() => import('../pages/tblondi/AssetsLibrary/AssetsLibraryMain')),
};

const ENGAGEMENT_TOOLS = {
	GENERIC: lazy(() => import('../pages/tblondi/EngagementTools/EngagementPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: mainPage.home.path,
		element: <HOME.MAIN />,
		exact: true,
	},
	{
		path: mainPage.discovery.subMenu.socialMedia.path,
		element: <DISCOVERY.SOCIAL_MEDIA />,
		exact: true,
	},
	// {
	// 	path: mainPage.discovery.subMenu.events.path,
	// 	element: <DISCOVERY.EVENTS />,
	// 	exact: true,
	// },
	{
		path: `${mainPage.discoveryItem.path}/:id`,
		element: <DISCOVERY.ITEM_SOCIAL />,
		exact: true,
	},
	// {
	// 	path: `${mainPage.eventItem.path}/:id`,
	// 	element: <DISCOVERY.ITEM_EVENT />,
	// 	exact: true,
	// },
	{
		path: `${mainPage.portfolio.path}`,
		element: <PORTFOLIO.MY_PORTFOLIO />,
		exact: true,
	},

	{
		path: `${mainPage.userProfile.path}`,
		element: <PROFILE.MY_PROFULE />,
		exact: true,
	},

	{
		path: `${mainPage.portfolio.subMenu.portfolioTitle.path}/`,
		element: <PORTFOLIO.PORTFOLIO_PAGE_ADD_TAG />,
		exact: true,
	},
	{
		path: `${mainPage.campaigns.path}/`,
		element: <CAMPAIGNS.CAMPAIGNS_MAIN />,
		exact: true,
	},
	{
		path: `${mainPage.campaignsAllList.path}/`,
		element: <CAMPAIGNS.CAMPAIGNS_LIST />,
		exact: true,
	},

	{
		path: `${mainPage.campaigns.path}/:id`,
		element: <CAMPAIGNS.CAMPAIGNS_ITEM />,
		exact: true,
	},

	{
		path: `${mainPage.portfolioItem.path}/:id`,
		element: <PORTFOLIO.PORTFOLIO_PAGE_ITEM />,
		exact: true,
	},
	// {
	// 	path: `${mainPage.portfolioItemEvent.path}/:id`,
	// 	element: <PORTFOLIO.PORTFOLIO_PAGE_EVENT />,
	// 	exact: true,
	// },
	{
		path: `${mainPage.engagementTools.path}`,
		element: <ENGAGEMENT_TOOLS.GENERIC />,
		exact: true,
	},
	{
		path: `${mainPage.assetsStorage.path}`,
		element: <ASSETS_STORAGE.ASSETS_STORAGE_MAIN />,
		exact: true,
	},
	{
		path: `${mainPage.welcome.path}`,
		element: <WELCOME.MAIN />,
		exact: true,
	},
	{
		path: `${mainPage.welcome.path}/:id`,
		element: <WELCOME.CREATOR />,
		exact: true,
	},
	{
		path: `${mainPage.welcome.path}/:id`,
		element: <WELCOME.BRAND />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
