import * as React from 'react';

function SvgVolumeUp(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M11.536 14.01A8.473 8.473 0 0014.026 8a8.473 8.473 0 00-2.49-6.01l-.708.707A7.476 7.476 0 0113.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z' />
			<path d='M10.121 12.596A6.48 6.48 0 0012.025 8a6.48 6.48 0 00-1.904-4.596l-.707.707A5.483 5.483 0 0111.025 8a5.483 5.483 0 01-1.61 3.89l.706.706z' />
			<path d='M10.025 8a4.486 4.486 0 01-1.318 3.182L8 10.475A3.489 3.489 0 009.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0110.025 8zM7 4a.5.5 0 00-.812-.39L3.825 5.5H1.5A.5.5 0 001 6v4a.5.5 0 00.5.5h2.325l2.363 1.89A.5.5 0 007 12V4zM4.312 6.39L6 5.04v5.92L4.312 9.61A.5.5 0 004 9.5H2v-3h2a.5.5 0 00.312-.11z' />
		</svg>
	);
}

export default SvgVolumeUp;
