import * as React from 'react';

function SvgSignalWifiStatusbarNotConnected(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			height='1em'
			viewBox='0 0 24 24'
			width='1em'
			className='svg-icon'
			{...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d='M18.52 15.16c.41-.73 1.18-1.16 1.63-1.8.48-.68.21-1.94-1.14-1.94-.88 0-1.32.67-1.5 1.23l-1.37-.57C16.51 10.96 17.52 10 19 10c1.23 0 2.08.56 2.51 1.26.37.6.58 1.73.01 2.57-.63.93-1.23 1.21-1.56 1.81-.13.24-.18.4-.18 1.18h-1.52c0-.41-.07-1.08.26-1.66zm-.57 3.79c0-.59.47-1.04 1.05-1.04.59 0 1.04.45 1.04 1.04 0 .58-.44 1.05-1.04 1.05-.58 0-1.05-.47-1.05-1.05z' />
			<path
				d='M14 13c0-2.76 2.24-5 5-5 1.63 0 3.07.79 3.98 2L24 8.98A16.88 16.88 0 0012 4C7.31 4 3.07 5.9 0 8.98L12 21l4.01-4.02C14.8 16.07 14 14.63 14 13z'
				fillOpacity={0.3}
			/>
		</svg>
	);
}

export default SvgSignalWifiStatusbarNotConnected;
