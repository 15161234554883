import * as React from 'react';

function SvgSmartwatch(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M9 5a.5.5 0 00-1 0v3H6a.5.5 0 000 1h2.5a.5.5 0 00.5-.5V5z' />
			<path d='M4 1.667v.383A2.5 2.5 0 002 4.5v7a2.5 2.5 0 002 2.45v.383C4 15.253 4.746 16 5.667 16h4.666c.92 0 1.667-.746 1.667-1.667v-.383a2.5 2.5 0 002-2.45V8h.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H14v-.5a2.5 2.5 0 00-2-2.45v-.383C12 .747 11.254 0 10.333 0H5.667C4.747 0 4 .746 4 1.667zM4.5 3h7A1.5 1.5 0 0113 4.5v7a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 013 11.5v-7A1.5 1.5 0 014.5 3z' />
		</svg>
	);
}

export default SvgSmartwatch;
