import * as React from 'react';

function SvgChatLeftQuoteFill(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M0 2a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H4.414a1 1 0 00-.707.293L.854 15.146A.5.5 0 010 14.793V2zm7.194 2.766a1.688 1.688 0 00-.227-.272 1.467 1.467 0 00-.469-.324l-.008-.004A1.785 1.785 0 005.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 00.011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 00-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 00.012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 00-.228-.4 1.686 1.686 0 00-.227-.273 1.466 1.466 0 00-.469-.324l-.008-.004A1.785 1.785 0 0010.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z' />
		</svg>
	);
}

export default SvgChatLeftQuoteFill;
