import React, { useState } from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import Modal, { ModalHeader, ModalBody } from '../../components/bootstrap/Modal';
import Button from '../../components/bootstrap/Button';
import Page from '../Page/Page';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();
	const [modalPrivacy, setModalPrivacy] = useState(false);

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row' style={{ display: 'flex', alignItems: 'center' }}>
					<div className='col'>
						<span className='fw-light'> © {new Date().getFullYear()} - Tblondi</span>
					</div>
					<div className='col-auto'>
						<Button
							onClick={() => setModalPrivacy(true)}
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>Privacy</small>
						</Button>
					</div>
				</div>
			</div>
			<Modal
				setIsOpen={setModalPrivacy}
				isOpen={modalPrivacy}
				size='xl'
				titleId='add-new-card'
				isCentered>
				<ModalHeader setIsOpen={setModalPrivacy}>
					{/* <ModalTitle id='add-new-card'>Card List</ModalTitle> */}
				</ModalHeader>
				<ModalBody>
					<Page>
						<div className='row'>
							<h3 className='fw-bold'>Privacy Policy</h3>
							<div className='col-12 my-2'>
								<div className='lead p-2'>
									<p className='fs-6'>
										Tblondi Martech GmbH, domiciled in Urbanstraße 133, 10967
										Berlin takes the protection of your personal data very
										seriously. The following Privacy Policy will provide you
										information about the personal data that is collected and
										how it is processed and used.
									</p>
									<h5 className='fw-bold'>
										Subject matter of this Privacy Policy
									</h5>
									<p className='fs-6'>
										This Privacy Policy applies to all services which are
										offered on Tblondi Pages/Apps. Unless stated otherwise, this
										Privacy Policy exclusively regulates how Tblondi treats your
										personal data. In case you make use of services provided by
										third parties, the privacy policies of these third parties
										apply exclusively. Tblondi does not revise privacy policies
										of third parties. We are collecting:
										<ul>
											<li>IP Address and Steam Name for the purpose of:</li>
											<li style={{ listStyleType: 'none' }}>
												<ul>
													<li style={{ listStyleType: 'circle' }}>
														Creating your account, verifying your
														identity, monitoring and securing the
														services we offer to you, to constantly
														improve our services and for the
														establishment, implementation and settlement
														of your contractual relationship.
													</li>
												</ul>
											</li>
										</ul>
									</p>
									<h5 className='fw-bold'>Data controller</h5>
									<p className='fs-6'>
										The data controller according to the Federal Data Protection
										Act is:
										<br />
										Tblondi Martech GmbH
										<br />
										Urbanstraße 133, 1096710967 Berlin
										<br />
										Managing Directors: Larisa Calaşnicov
										<br />
										email:{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										<br />
										Sitz/Registergericht Berlin Kreuzberg: HRB 1533
										<br />
										In case you have any questions concerning privacy policy
										issues, please contact{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										. At this address, you can inquire about privacy issues and
										review, change or delete your personal data stored by
										Tblondi. Alternatively, you can also contact us using the
										above postal address. This will cause you no other costs
										than the transmission costs stated in the base rates (i.e.
										the costs of your Internet, e-mail, mobile phone or
										telephone connection).
									</p>
									<h5 className='fw-bold'>General information</h5>
									<p className='fs-6'>
										We collect, process and use personal data – unless already
										permitted by statutes – only with your consent. If the
										consent is declared electronically within the scope of our
										Internet presence, we will comply with the statutory
										obligations to inform you about it.
									</p>
									<h5 className='fw-bold'>
										Collection of data to implement the contract
									</h5>
									<p className='fs-6'>
										Generally, you can access our Internet presence without
										disclosing any personal data to us. It is always your choice
										whether or not to provide us with your personal data. We
										collect your data to be able to provide information about
										our services for you and to provide you with certain
										features.
									</p>
									<h5 className='fw-bold'>Google Analytics</h5>
									<p className='fs-6'>
										This website and mobile application uses Google Analytics, a
										web/app analysis service provided by Google Inc. (“Google”).
										Google Analytics uses so-called “cookies”, i.e. text files
										that are stored on your computer to enable your use of the
										website to be analysed. The information relating to your use
										of this website generated by the cookie is usually
										transmitted to and stored by a Google server in the USA. If
										IP anonymisation is activated on this website, however, your
										IP address will be shortened beforehand by Google within
										member states of the European Union or other contracting
										states to the Treaty on the European Economic Area. The full
										IP address will be transmitted to a Google server in the USA
										and shortened there only on an exceptional basis. On behalf
										of the operator of this website, Google will use this
										information for the purpose of evaluating your use of this
										website, compiling website activity reports and providing
										further services relating to website activity and Internet
										use to Tblondi. The IP address transmitted from your browser
										via Google Analytics will not be associated with any other
										data held by Google. You may prevent the use of cookies by
										selecting the appropriate setting in your browser software;
										however, please note that in this case you may not be able
										to use all functions of this website to full extent. You may
										also prevent the collection and processing of the data
										generated by the cookie and relating to your use of this
										website (incl. your IP address) by Google by downloading and
										installing the browser plug-in, which is available under the
										following link:{' '}
										<a
											href='http://tools.google.com/dlpage/gaoptout?hl=de'
											target='blank'>
											http://tools.google.com/dlpage/gaoptout?hl=de
										</a>{' '}
										. In case you are using our mobile app, in addition to
										Google Analytics, we also use Firebase, which uses cookies
										to track visitor usage. The Firebase software will save a
										cookie to your device in order to track and monitor your
										engagement and usage of the mobile application, but will not
										store, save or collect personal information. You can read
										Google's privacy policy here for further information
										www.google.com/intl/en/policies/privacy/ Other cookies may
										be stored to your computer's hard drive or device by
										external vendors when this website uses referral programs,
										sponsored links or adverts. Such cookies are used for
										conversion and referral tracking and typically expire after
										30 days, though some may take longer. No personal
										information is stored, saved or collected. It further uses
										Amplitude. You can read Amplitude’s privacy policy here for
										further information.{' '}
										<a href='https://amplitude.com/privacy' target='blank'>
											https://amplitude.com/privacy
										</a>
									</p>
									<h5 className='fw-bold'>Cookies</h5>
									<p className='fs-6'>
										For technical purposes and to make the visit to our Internet
										presence as pleasant as possible, we use, like many other
										companies, our own cookies in addition to Google Analytics
										cookies described above. After your visit to our websites,
										these cookies are usually automatically deleted from your
										hard drive; however, some cookies may be stored on your hard
										drive for a longer period of time for technical reasons.
										Your Internet browser can be set to reject cookies generally
										or delete them in individual cases. If you wish to prevent
										cookies from being installed or would like to delete
										individual cookies, please follow the instructions of the
										manufacturer of your browser. Please note that you may no
										longer be able to use our Internet pages or only in a
										limited manner for technical reasons.
									</p>
									<h5 className='fw-bold'>
										Collection of data for statistical purposes
									</h5>
									<p className='fs-6'>
										Within the scope of statistical evaluations, we collect in
										some areas of our Internet presence data such as operating
										system and version, browser and version, IP address as well
										as further system data. We use this data for statistical
										evaluations, in particular for the purpose of adapting our
										Internet presence to the users’ needs. Where possible, the
										data is anonymised.
									</p>
									<h5 className='fw-bold'>Adverts and Sponsored Links</h5>
									<p className='fs-6'>
										This website and mobile application may contain sponsored
										links and adverts. These will typically be served through
										our advertising partners, to whom may have detailed privacy
										policies relating directly to the adverts they serve.
										Clicking on any such adverts will send you to the
										advertisers website through a referral program which may use
										cookies and will track the number of referrals sent from
										this website. This may include the use of cookies which may
										in turn be saved on your computer's hard drive or device.
										Users should therefore note they click on sponsored external
										links at their own risk and we cannot be held liable for any
										damages or implications caused by visiting any external
										links mentioned.
									</p>
									<h5 className='fw-bold'>Withdrawal of consents</h5>
									<p className='fs-6'>
										Insofar as the processing of your personal data is based on
										your consent, you may withdraw your consent to data
										processing at any time with effect for the future. Insofar
										as your withdrawal refers to the receipt of newsletters or
										other e-mails, you will be informed as part of the
										communication that you may cancel your subscription at any
										time. The cancellation of your subscription will cause you
										no costs other than the transmission costs stated in the
										base rates. Otherwise, please refer to the support of the
										website or app you used to withdraw your consent. The
										cancellation of your subscription will cause you no other
										costs than the transmission costs stated in the base rates.
										To notify us, that you wish to withdraw your consent to
										Tblondi using and sharing your personal information for a
										particular purpose(s), please send an email to the following
										address:{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										, or send a letter to the following address:
										<br />
										Tblondi Martech GmbH
										<br />
										Urbanstraße 133, 1096710967 Berlin
										<br />
										Managing Directors: Larisa Calaşnicov
										<br />
										email:{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										<br />
										Once we receive your notice that you wish to withdraw
										consent, we will contact you to acknowledge receipt of your
										withdrawal notice.Tblondi will cease to use and share your
										personal information without delay. Please note that after
										your withdrawal of consent, Tblondi will delete your
										personal information unless Tblondi is obliged to retain
										your personal information for business (e.g. to wind up your
										contractual relationship) and legal purposes in accordance
										with applicable legislation. According to German data
										protection law, you have the right of information,
										correction and deletion of your personal data. To notify us,
										if you wish to do so, please send an email to the following
										address:{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										, or send a letter to the following address: Tblondi Martech
										GmbH
										<br />
										Urbanstraße 133, 1096710967 Berlin
									</p>
									<h5 className='fw-bold'>Use of your personal data</h5>
									<p className='fs-6'>
										We use your personal data in order to constantly improve our
										range of services and to satisfy the users’ needs. Tblondi
										uses your personal data for the establishment,
										implementation and settlement of your contractual
										relationship with Tblondi and the processing of payments.
										Furthermore, we use your data in order to communicate with
										you. This includes that we, as the case may be, inform you
										about novelties of our services by e-mail. Thus, we use your
										data to regularly inform you about products, services or
										certain events from our range of services and our corporate
										group’s range of services which may be of interest to you.
										You may also be sent e-mails within the scope of customer
										surveys. You may receive emails from us from the following
										address:{' '}
										<a href='mailto:accounts@tblondi.com'>
											accounts@tblondi.com
										</a>
										. Finally, we use your data in order to protect our websites
										against abusive use and to trace any unauthorised access.
									</p>
									<h5 className='fw-bold'>Disclosure of personal data</h5>
									<p className='fs-6'>
										As a matter of course, your data will not be sold to third
										parties. In addition to using Google Analytics, Tblondi
										exclusively discloses your personal data to third parties to
										the following extent: We cooperate with, providers that
										create and compile statistical data, IT service providers
										(e.g. data centres, hosts, backup services, database
										services), communication providers (e.g. sms and email
										services, push notifications, etc.) and payment providers
										(e.g. Apple Pay etc.). These service providers only have
										access to your data to the extent which is necessary for the
										completion of their tasks, which is the establishment,
										implementation and the settlement of your contractual
										relationship with Tblondi, the processing of payments and in
										order to communicate with you. These service providers are
										obliged to treat your data in accordance with mandatory
										German and European data protection law. In the case that
										Tblondi Martech GmbH, companies controlled by Tblondi
										Martech GmbH, particular affiliated companies or shares of
										the company are sold, your customer data will generally be
										passed on with the transmitted part of the company. Of
										course, in this case your data is also subject to this
										Privacy Policy and the relevant data protection statutes. We
										may transfer your personal data outside the European
										Economic Area (e.g. to companies domiciled in the USA and
										whose servers are located in the USA).
									</p>
									<h5 className='fw-bold'>Deletion of data</h5>
									<p className='fs-6'>
										If your data is no longer necessary for the above-mentioned
										purposes including settlement, for tax purposes or other
										legal reasons, it will be deleted.
									</p>
									<h5 className='fw-bold'>Security of your personal data</h5>
									<p className='fs-6'>
										We process the data collected from you according to the
										German Data Protection Act. All employees are obliged and
										have been advised to maintain data confidentiality and
										privacy obligations.
									</p>
									<h5 className='fw-bold'>Change of Privacy Policy</h5>
									<p className='fs-6'>
										We may change our Privacy Policy from time to time. You will
										be informed of changes to our Privacy Policy. Please check
										our website to find the newest version of our Privacy
										Policy.
									</p>
								</div>
							</div>
						</div>
					</Page>
				</ModalBody>
			</Modal>
		</footer>
	);
};

export default Footer;
