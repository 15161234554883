import * as React from 'react';

function SvgFileEarmarkPpt(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M7 5.5a1 1 0 00-1 1V13a.5.5 0 001 0v-2h1.188a2.75 2.75 0 000-5.5H7zM8.188 10H7V6.5h1.188a1.75 1.75 0 110 3.5z' />
			<path d='M14 4.5V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2h5.5L14 4.5zm-3 0A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V4.5h-2z' />
		</svg>
	);
}

export default SvgFileEarmarkPpt;
