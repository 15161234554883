import React from 'react';
// import Header from '../../../layout/Header/Header';
// , { HeaderLeft }
//  from '../../../layout/Header/Header';
// import CommonHeaderChat from './CommonHeaderChat';
// import Search from '../../../components/Search';
// import CommonHeaderRight from './CommonHeaderRight';

const DashboardHeader = () => {
	return (
		//  <Header>
		// 	 <HeaderLeft>
		// 		 <Search />
		// 	 </HeaderLeft>
		// 	 <CommonHeaderRight afterChildren={<CommonHeaderChat />} /> 
		//  </Header> 
		<>
		</>
	);
};

export default DashboardHeader;
