import * as React from 'react';

function SvgStack(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M14.12 10.163l1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 01-.534 0L.165 11.555a.299.299 0 010-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 01.534 0l7.568 3.784a.3.3 0 010 .535L8.267 8.165a.598.598 0 01-.534 0L.165 4.382a.299.299 0 010-.535L7.733.063z' />
			<path d='M14.12 6.576l1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 01-.534 0L.165 7.968a.299.299 0 010-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z' />
		</svg>
	);
}

export default SvgStack;
