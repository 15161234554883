import * as React from 'react';

function SvgEmojiExpressionlessFill(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8 16A8 8 0 108 0a8 8 0 000 16zM4.5 6h2a.5.5 0 010 1h-2a.5.5 0 010-1zm5 0h2a.5.5 0 010 1h-2a.5.5 0 010-1zm-5 4h7a.5.5 0 010 1h-7a.5.5 0 010-1z' />
		</svg>
	);
}

export default SvgEmojiExpressionlessFill;
