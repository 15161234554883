import * as React from 'react';

function SvgEarbuds(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M6.825 4.138c.596 2.141-.36 3.593-2.389 4.117a4.432 4.432 0 01-2.018.054c-.048-.01.9 2.778 1.522 4.61l.41 1.205a.52.52 0 01-.346.659l-.593.19a.548.548 0 01-.69-.34L.184 6.99c-.696-2.137.662-4.309 2.564-4.8 2.029-.523 3.402 0 4.076 1.948zm-.868 2.221c.43-.112.561-.993.292-1.969-.269-.975-.836-1.675-1.266-1.563-.43.112-.561.994-.292 1.969.269.975.836 1.675 1.266 1.563zm3.218-2.221c-.596 2.141.36 3.593 2.389 4.117a4.434 4.434 0 002.018.054c.048-.01-.9 2.778-1.522 4.61l-.41 1.205a.52.52 0 00.346.659l.593.19c.289.092.6-.06.69-.34l2.536-7.643c.696-2.137-.662-4.309-2.564-4.8-2.029-.523-3.402 0-4.076 1.948zm.868 2.221c-.43-.112-.561-.993-.292-1.969.269-.975.836-1.675 1.266-1.563.43.112.561.994.292 1.969-.269.975-.836 1.675-1.266 1.563z'
			/>
		</svg>
	);
}

export default SvgEarbuds;
