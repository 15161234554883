import * as React from 'react';

function SvgFileImage(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M8.002 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' />
			<path d='M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zM3 2a1 1 0 011-1h8a1 1 0 011 1v8l-2.083-2.083a.5.5 0 00-.76.063L8 11 5.835 9.7a.5.5 0 00-.611.076L3 12V2z' />
		</svg>
	);
}

export default SvgFileImage;
