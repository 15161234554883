import * as React from 'react';

function SvgBezier(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path
				fillRule='evenodd'
				d='M0 10.5A1.5 1.5 0 011.5 9h1A1.5 1.5 0 014 10.5v1A1.5 1.5 0 012.5 13h-1A1.5 1.5 0 010 11.5v-1zm1.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zm10.5.5A1.5 1.5 0 0113.5 9h1a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-1a1.5 1.5 0 01-1.5-1.5v-1zm1.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1zM6 4.5A1.5 1.5 0 017.5 3h1A1.5 1.5 0 0110 4.5v1A1.5 1.5 0 018.5 7h-1A1.5 1.5 0 016 5.5v-1zM7.5 4a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1z'
			/>
			<path d='M6 4.5H1.866a1 1 0 100 1h2.668A6.517 6.517 0 001.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 013.185-3.185A1.503 1.503 0 016 5.5v-1zm3.957 1.358A1.5 1.5 0 0010 5.5v-1h4.134a1 1 0 110 1h-2.668a6.517 6.517 0 012.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 00-3.185-3.185z' />
		</svg>
	);
}

export default SvgBezier;
