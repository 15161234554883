import * as React from 'react';

function SvgRecycle(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M9.302 1.256a1.5 1.5 0 00-2.604 0l-1.704 2.98a.5.5 0 00.869.497l1.703-2.981a.5.5 0 01.868 0l2.54 4.444-1.256-.337a.5.5 0 10-.26.966l2.415.647a.5.5 0 00.613-.353l.647-2.415a.5.5 0 10-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 11-.26-.966l2.416-.647a.5.5 0 01.612.353l.647 2.415a.5.5 0 01-.966.259l-.333-1.242-2.545 4.454a.5.5 0 00.434.748H5a.5.5 0 010 1H1.723A1.5 1.5 0 01.421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 10-.868.496l1.716 3.004a.5.5 0 01-.434.748h-5.57l.647-.646a.5.5 0 10-.708-.707l-1.5 1.5a.498.498 0 000 .707l1.5 1.5a.5.5 0 10.708-.707l-.647-.647h5.57a1.5 1.5 0 001.302-2.244l-1.716-3.004z' />
		</svg>
	);
}

export default SvgRecycle;
