import * as React from 'react';

function SvgSafe2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='currentColor'
			className='svg-icon'
			viewBox='0 0 16 16'
			{...props}>
			<path d='M1 2.5A1.5 1.5 0 012.5 1h12A1.5 1.5 0 0116 2.5v12a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 011 14.5V14H.5a.5.5 0 010-1H1V9H.5a.5.5 0 010-1H1V4H.5a.5.5 0 010-1H1v-.5zM2.5 2a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-12z' />
			<path d='M5.035 8h1.528c.047-.184.12-.357.214-.516l-1.08-1.08A3.482 3.482 0 005.035 8zm1.369-2.303l1.08 1.08c.16-.094.332-.167.516-.214V5.035a3.482 3.482 0 00-1.596.662zM9 5.035v1.528c.184.047.357.12.516.214l1.08-1.08A3.482 3.482 0 009 5.035zm2.303 1.369l-1.08 1.08c.094.16.167.332.214.516h1.528a3.483 3.483 0 00-.662-1.596zM11.965 9h-1.528c-.047.184-.12.357-.214.516l1.08 1.08A3.483 3.483 0 0011.965 9zm-1.369 2.303l-1.08-1.08c-.16.094-.332.167-.516.214v1.528a3.483 3.483 0 001.596-.662zM8 11.965v-1.528a1.989 1.989 0 01-.516-.214l-1.08 1.08A3.483 3.483 0 008 11.965zm-2.303-1.369l1.08-1.08A1.988 1.988 0 016.563 9H5.035c.085.593.319 1.138.662 1.596zM4 8.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zm4.5-1a1 1 0 100 2 1 1 0 000-2z' />
		</svg>
	);
}

export default SvgSafe2;
