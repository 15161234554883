export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const mainPage = {
	home: {
		id: 'home',
		text: 'Home',
		path: '/',
		icon: 'Home',
		subMenu: null,
	},
	discovery: {
		id: 'discovery',
		text: 'Discovery',
		path: 'discovery',
		icon: 'Explore',
		subMenu: {
			socialMedia: {
				id: 'socialMedia',
				text: 'Creators',
				path: 'discovery/social-media',
				icon: 'PersonSearch',
			},
			// events: {
			// 	id: 'events',
			// 	text: 'Events',
			// 	path: 'discovery/events',
			// 	icon: 'EmojiEvents',
			// },
		},
	},
	portfolio: {
		id: 'portfolio',
		text: 'Portfolio',
		path: '/portfolio',
		icon: 'Cases',
		subMenu: {
			portfolio: {
				id: 'portfolio',
				text: 'Portfolio',
				path: '/portfolio',
				icon: 'Cases',
			},
			portfolioTitle: {
				id: 'portfolioTitle',
				text: 'Portfolio tag',
				path: '/portfolio-title',
				icon: 'Tag',
			},
		},
	},

	campaigns: {
		id: 'campaigns',
		text: 'Campaigns',
		path: '/campaigns',
		icon: 'ManageAccounts',
		subMenu: null,
		// hide: true,
	},
	assetsStorage: {
		id: 'assetLibrary',
		text: 'Asset library',
		path: 'asset-library',
		icon: 'PhotoLibrary',
		subMenu: null,
	},
	campaignsAllList: {
		id: 'campaigns',
		text: 'Campaigns',
		path: '/campaigns-list',
		icon: 'ManageAccounts',
		subMenu: null,
		hide: true,
	},

	engagementTools: {
		id: 'engagementTools',
		text: 'Engagement Tools',
		path: '/engagementTools',
		icon: 'ManageAccounts',
		subMenu: null,
		hide: true,
	},

	portfolioItem: {
		id: 'portfolioItem',
		path: '/portfolio-media',
		subMenu: null,
		hide: true,
	},

	portfolioItemEvent: {
		id: 'portfolioItemEvent',
		path: '/portfolio-event',
		subMenu: null,
		hide: true,
	},

	discoveryItem: {
		id: 'discoveryItem',
		path: 'discovery/social-media',
		subMenu: null,
		hide: true,
	},

	eventItem: {
		id: 'eventItem',
		path: 'discovery/events',
		subMenu: null,
		hide: true,
	},

	campaignItem: {
		id: 'eventItem',
		path: '/campaigns',
		subMenu: null,
		hide: true,
	},

	welcome: {
		id: 'welcome',
		path: '/welcome',
		hide: true,
	},

	userProfile: {
		id: 'userProfile',
		// text: 'Home',
		path: 'profile',
		// icon: 'Home',
		subMenu: null,
		hide: true,
	},

	// onlyContent: {
	// 	id: 'onlyContent',
	// 	text: 'Only Content',
	// 	path: 'page-layouts/only-content',
	// 	icon: 'WebAsset',
	// },
	// pricingTable: {
	// 	id: 'pricingTable',
	// 	text: 'Pricing Table',
	// 	path: 'pricing-table',
	// 	icon: 'Local Offer',
	// },
	// summary: {
	// 	id: 'summary',
	// 	text: 'Summary',
	// 	path: 'summary',
	// 	icon: 'sticky_note_2',
	// },
};
